// Code generated by Slice Machine. DO NOT EDIT.

import { defineAsyncComponent } from "vue";
import { defineSliceZoneComponents } from "@prismicio/vue";

export const components = defineSliceZoneComponents({
  accordion: defineAsyncComponent(() => import("./Accordion/index.vue")),
  card_grid: defineAsyncComponent(() => import("./CardGrid/index.vue")),
  card_list: defineAsyncComponent(() => import("./CardList/index.vue")),
  cards: defineAsyncComponent(() => import("./PagePush/index.vue")),
  contact_form: defineAsyncComponent(() => import("./ContactForm/index.vue")),
  featured_text: defineAsyncComponent(() => import("./PromoText/index.vue")),
  hero_header: defineAsyncComponent(() => import("./HeroHeader/index.vue")),
  image_gallery: defineAsyncComponent(() => import("./ImageGallery/index.vue")),
  intro_link: defineAsyncComponent(() => import("./TextLink/index.vue")),
  memberships: defineAsyncComponent(() => import("./Memberships/index.vue")),
  promo_box: defineAsyncComponent(() => import("./PromoBanner/index.vue")),
  quote: defineAsyncComponent(() => import("./Quote/index.vue")),
  rich_content: defineAsyncComponent(() => import("./RichContent/index.vue")),
  sponsors: defineAsyncComponent(() => import("./Sponsors/index.vue")),
  tabbed_content: defineAsyncComponent(() => import("./TextImage/index.vue")),
  termly_policy: defineAsyncComponent(() => import("./TermlyPolicy/index.vue")),
  text_image: defineAsyncComponent(() => import("./PromoImage/index.vue")),
  ticker_tape: defineAsyncComponent(() => import("./TickerTape/index.vue")),
  whats_on: defineAsyncComponent(() => import("./WhatsOn/index.vue")),
});
